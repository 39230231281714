// @use 'base';
@import'../base.scss';
.menu{
    font-family: Futura, "Roboto", 'Helvetica Neue', sans-serif,Helvetica, sans-serif;
    font-size: 1.2em;
    text-align: left;

    /* position: sticky; */
    position: fixed;
    /* position: static; */
    /* font-style: italic; */
    /* position: -webkit-sticky;  */
    top:0;
    width: 100%;
    /* margin: 1em 4rem; */
    /* height: 50px; */
    //  background: #ffffffc7;
    z-index: 10;
    margin-top: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    // text-shadow: 0px 0px 5px rgb(24, 23, 24);
    /* width: 80%; */
    /* display: inline-block; */
    /* display: inline-block; */
    /* position: absolute; */
    /* left: 10px; */
    
    /* float: none; */
    /* position: absolute; */
      

}



.menu a{
    color:$primeTextColor;
    transition: 1s;
    position: relative;
    
}

.menu a:hover{
    color: $hoverColor;
    letter-spacing: 1px;
   
}

/* .menu a:hover{
    color: #ff0080;
} */

.logo{
display: flex;
flex-direction: column;
margin: 0em 5vw;
}


.myName{
    font-family: 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, sans-serif;
    /* font-style: italic; */
    /* font-weight: lighter; */
    /* font-weight: 700; */
    font-size: 1.5em;
    word-spacing: -.2ch;
    /* line-height: 1; */
    position: relative;
    /* display:inline; */
    /* text-decoration: none; */
    
}
.recent{
    font-size: 0.6em;
    padding:0;
    margin: none;
    /* display: inline-block; */
    width: 100%;
    vertical-align: text-top;
    
    /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
}


.menu  a.homeOn{
    /* color: white; */
    /* font-size: 1.1em; */
    /* word-spacing: 1ch; */
    /* display: inline-block; */
    /* padding:0 1rem; */
    cursor: pointer;
    transition: 1s;
    opacity: 1;
    margin: 0;
    color: $primeTextColor;

   
    /* border-left: 2px  solid */
}


.menu a.aboutOn{
    color: $primeTextColor;
}

.about{
    margin: 0em 5vw;
    font-size: 1em;
    /* display: abo; */
    /* position: absolute; */
    padding:0em;
    // margin: none;
    cursor: pointer;
    float: right;
    
   
    right:0;
    // position: absolute; */
    /* bottom: 0; */
    
}
.about{
    position: relative;
    
    /* position: fixed; */
}
.about div:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0;
    // background-color: $primeTextColor;
    background-color: $primeTextColor;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}
.about div:hover:before{
    transform: scaleX(1);
    visibility: visible;
    
  /* transition: all 0.3s ease-in-out; */
    /* text-decoration: underline; */
}

.myName div:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0;

    background-color: $primeTextColor;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}


.myName div:hover:before{
    transform: scaleX(1);
    visibility: visible;
    
  /* transition: all 0.3s ease-in-out; */
    /* text-decoration: underline; */
}

@media only screen and (max-width: 768px) {
    
    .menu{
        font-family:  Futura, "Roboto", 'Helvetica Neue', sans-serif,Helvetica, sans-serif;
        /* font-size: 1.3em; */
        text-align: left;
        /* padding: 0rem 1.5rem; */
        position: fixed;
        
        /* font-style: italic; */
        position: -webkit-sticky; 
        top:0;
        /* background: #ffffff; */
        z-index: 10;
        margin-top: 0.5em;
        /* display: inline-block; */
        /* display: inline-block; */
        /* position: absolute; */
        /* left: 10px; */
        
        /* float: none; */
        /* position: absolute; */
        
    
    }



}