// @import'../base.scss';

.pwBG{
  position: relative;
  display:flex ;
flex-direction: column;
   width: 100%;
  //  height: 100%;
   /* height: 600px;  */
   height: 60vh; 
  /* margin: 0 3rem; */
  margin: 6rem 0 2rem;
  z-index: 2;
  justify-content: center; 
  align-items: center;
  align-content:center;

}
.pwstyle{
color:white;
position: relative;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
z-index: 100;
// background-color: violet;
// height:50vh;
// min-width: 28%;
  // width: 20%;
  // max-width: 1080px;
  // padding-top: 50%;
}
input{
  border-radius: 10px;
  padding: 4px 8px;
  margin: 5px 0;
}
input:focus { 
  outline: none !important;
  border-color: #9c71ce;
  box-shadow: 0 0px 0px #7c3ea5;
 
}