$mainTitileFont: 'Futura', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
$SecondaryTitileFont: 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

$primeBgColor: rgb(24, 23, 24);
$hoverColor:rgb(255, 255, 255) ;
$linkColor:rgb(194, 194, 194) ;
$linkHoverColor:rgb(238, 238, 238);
$primeTextColor: rgb(236, 236, 236);

