body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(245, 245, 245); */
  /* background-color: rgb(235, 235, 235); */
  overflow-x: hidden;
}

@font-face {
  font-family: 'Fututra';
  src: local('Fututra'), url(./fonts/FuturaMediumBT.ttf) format('truetype');
}

@font-face {
  font-family: 'FututraBold';
  src: local('FututraBold'), url(./fonts/FuturaBoldfont.ttf) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	/* font-size: 100%; */
	
	vertical-align: baseline;
}

body {
	/* line-height: 1; */
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  position: absolute;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(175, 134, 21); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

