 @import'./base.scss';

.App {
  /* text-align: center; */
  width: 100%;
  /* height:auto; */
  top:0;
  background-color: $primeBgColor;
  position: absolute;
}

a{
  padding: none;
  margin: none;  
  }


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.canvasContainer{
  position: fixed;
  display: inline-block;
  width: 100%;
  height: 100%;
  top:0;

  left:0;
  z-index: 1;
  background-color: $primeBgColor;
  /* overflow: hidden; */
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}



/* .goBackIcon:hover{ */
  /* opacity: 0.5;
  
  
  transition: 1s; */
/* } */
.gridContainer{
  position: relative;
  display:flex ;

   width: 100%;
   /* height: 600px;  */
  /* height: 30%; */
  /* margin: 0 3rem; */
  margin: 6rem 0 2rem;
  z-index: 2;
  
  /* justify-content: center; */
  /* margin-top: 10%; */
  /* min-height: 30%; */
  /* min-width: 300px; */
  

}
.gridSystem{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* margin-left: 4em; */
  /* margin-right: 1.5vw; */
  /* margin-top: 5rem; */
  align-items: center;
  justify-content: center;
  /* align-items: center; */
  padding: none;
  animation: fadein 1s;
  /* height:150%; */
  /* width: 100%; */
  /* height: 500px;  */


}

.projectContentContainer{
  position: relative;
  /* background: rgb(235, 235, 235); */
  /* margin-bottom: 5rem; */
  max-width: 4000px;
  /* top:10px; */
  /* height: 100vh; */
  z-index: 2;
  /* padding:5rem 0; */
  width: 100%;
  perspective: 2000px;
  /* height: 100vh; */

}

.footer{
  font-family: "Futura";
  margin:1rem 5% 1rem;
  font-size: 0.8rem;
  float: left;
  width: 60%;
  opacity: 1;
  // color: $primeTextColor;
  color:white !important;
  animation: fadein 4s;
  /* bottom: 5px; */
  z-index: 30;
  /* color: #ff0080; */
  position: relative;
  
}


@media only screen and (max-width: 768px) {
  .goBack{
    padding: 15px 0px;
    text-align: left;
    /* display: inline-block; */
    /* position: absolute; */
    /* margin: 3rem; */
    /* left:6rem;  */
    cursor: pointer;
    transition: 1s;
    animation: fadein 4s;
    /* opacity: 0.5; */
    width: 80px;
    /* height:60px; */
    background-color: #ff0080;
    color: black;
    z-index: 30;
    margin:0;
    position: fixed;
    /* position: -webkit-sticky;  */
    top:4rem;
    
  }
  .gridSystem{
    margin:0 1.5em 0;
    margin-top: 0em;
    position: relative;
    display: flex;
    flex-wrap: wrap;
   

    align-items: center;
    /* justify-content: left; */
    flex-direction:column;
    padding: none;
    /* margin: none; */
    animation: fadein 1s;
  
  }
  .footer{
    margin-left: 1.5rem;
    /* padding: 10px; */
    font-size: 10px;
    float: left;
    /* width: 100%; */
    display: block;
    animation: fadein 4s;
  }


}

.ErroPage{
  position: relative;
  display:flex ;
flex-direction: column;
   width: 100%;
  //  height: 100%;
   /* height: 600px;  */
   height: 60vh; 
  /* margin: 0 3rem; */
  margin: 6rem 0 2rem;
  z-index: 2;
  justify-content: center; 
  align-items: center;
  align-content:center;
  color: white;
  
}